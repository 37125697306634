import axios from 'axios'

export const listAppointments = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/appointments`, {
    headers: {
      'authorization': localStorage.getItem('token'),
    },
    params
  })
  return response.data
}

export const addAppointmentBiometrics = async (appointmentId, patientId, params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${appointmentId}/biometrics`, {
    ...params,
    patientId
  }, {
    headers: {
      'authorization': localStorage.getItem('token')
    }
  })
  return response.data
}

export const listAppointmentBiometrics = async (appointmentId, patientId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${appointmentId}/biometrics`, {
    params: { patientId },
    headers: {
      'authorization': localStorage.getItem('token')
    }
  })
  return response.data
}
