import React, { useEffect, useState } from "react";
import { message, Row, Button } from "antd";
import {
  UserOutlined,
  HomeOutlined,
  PhoneOutlined,
  CalendarOutlined,
  TagOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import * as Barcode from "react-barcode";
import "./style.scss";
import { listLabels } from "../../services/label.service.js";

export const TylerBeadeLabels = () => {
  const { search } = useLocation();
  const [labels, setLabels] = useState([]);
  const LabelKey = {
    INSTALAB: "instalab",
    LAB: "lab",
  };
  const LabelCount = {
    [LabelKey.INSTALAB]: 0,
    [LabelKey.LAB]: 6,
  };

  const getLabel = (rowIndex) => {
    if (labels.length <= rowIndex) return null;
    return labels[rowIndex];
  };

  function print() {
    window.print();
  }

  return (
    <div className="print-labels-component">
      <div className="print-button" onClick={print}>
        <Button type="primary">
          <PrinterOutlined /> Print Labels
        </Button>
      </div>

      {Array.from(Array(6)).map((_, rowIndex) => {
        return (
          <Row className="label" key={`row_${rowIndex}`}>
            <div className="label-cell">
              <div className={`${rowIndex}-cell-content`}>
                <>
                  <div className="barcode-container">
                    <Barcode
                      value={'6439080'}
                      displayValue={'6439080'}
                      width={1}
                      height={13}
                      margin={0}
                      fontSize={5}
                    />
                  </div>
                  <table className="lab-table">
                    <tr>
                      <td>Name</td>
                      <td>Tyler Beede</td>
                    </tr>
                    <tr>
                      <td>DOB</td>
                      <td>05/23/1993</td>
                    </tr>
                    <tr>
                      <td>Collected</td>
                      <td>
                        07/25/24 at 9:00 AM
                      </td>
                    </tr>
                  </table>
                </>
              </div>
            </div>
          </Row>
        );
      })}
    </div>
  )
};
