import axios from 'axios'

export const register = async (params) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/register`, params)
    localStorage.setItem('token', response.data.token)
    return response.data
}

export const login = async (params) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/login`, params)
    localStorage.setItem('token', response.data.token)
    return response.data
}

export const addKey = async (params) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/keys`, params, {
        headers: {
            'authorization': localStorage.getItem('token')
        }
    })
    return response.data
}

export const logout = () => {
    localStorage.removeItem('token')
    window.open('/', '_self')
}