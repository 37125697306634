import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { MenuOutlined, LeftOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import './style.scss'


export const Header = ({ menu }) => {
    const [visible, setVisible] = useState(false)
    const location = useLocation()

    const BLACKLIST = [
        "/labels"
    ]

    return BLACKLIST.some(match => location.pathname.includes(match)) ? null : (
        <div className="header">
            <Button
                className="menu-btn"
                icon={<MenuOutlined />}
                onClick={() => setVisible(true)}
            />

            <Drawer
                placement="left"
                onClick={() => setVisible(false)}
                onClose={() => setVisible(false)}
                visible={visible}
                className="menu-drawer"
                closeIcon={<LeftOutlined />}
                getContainer={false}
            > 
                {menu}
            </Drawer>

            <div className="logo">
                Insta<b>lab</b>
            </div>
        </div>
    )
}