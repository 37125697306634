import 'antd/dist/antd.min.css'
import React, { useState, useMemo } from 'react'
import { Layout } from 'antd'
import { isLoggedIn } from "./helpers/auth.helper";
import { logout } from './services/auth.service';
import { UserContext } from "./contexts/user.context";
import {
  BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";
import { NavMenu } from './components/navMenu/navMenu.component';
import { Header } from './components/header/header.component';
import { Sidebar } from './components/sidebar/sidebar.component';
import { Login } from './components/login/login.component.js'
import { Appointments } from './components/appointments/appointments.component';
import { PrintLabels } from './components/printLabels/printLabels.component';
import { Badge } from './components/badge/badge.component';

import './App.scss';
import { TylerBeadeLabels } from './components/tylerBeadeLabels/tylerBeadeLabels.component.js';

function App() {
	const [isAuthed, setIsAuthed] = useState(isLoggedIn)

	const userValue = useMemo(() => ({ 
		isAuthed, 
		setIsAuthed,
	}), [
		isAuthed, 
		setIsAuthed,
	])

	const items = isAuthed ? [{
		path: '/appointments',
		text: 'Appointments'
	}, {
		path: '/badge',
		text: 'Badge'
	},
	{
		path: '/logout',
		text: "Log out",
		onClick: () => {
			logout()
			setIsAuthed(false)
		}
	},
	] : [{
		path: '/login',
		text: "Log in",
	}];

	const Menu = (
		<NavMenu
			items={items}
		/>
	)

  return (
		<UserContext.Provider value={userValue}>
			<Router>
				<Header menu={Menu} />
				<Layout className="layout">
					<Sidebar menu={Menu} />
					
					<Routes>
						<Route path="/login" element={<Login/>} />
						<Route path="/appointments" element={<Appointments />} />
						<Route path="/labels" element={isAuthed ? <PrintLabels /> : <Login/>} />
						<Route path="/badge" element={isAuthed ? <Badge /> : <Login/>} />
						<Route path="/tyler-beede" element={isAuthed ? <TylerBeadeLabels /> : <Login />} />
						<Route path="/" element={isAuthed ? <Appointments /> : <Login/>} />

					</Routes>
				</Layout>
			</Router>
		</UserContext.Provider>
  )
}

export default App;
