import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { Typography, Row, Col, message } from "antd";
import { SwipeAction, Tag } from "antd-mobile";
import { getMe } from "../../services/user.service.js";
import { listAppointments } from "../../services/appointment.service.js";
import { BiometricForm } from "../biometricForm/biometricForm.component.js";
import { PatientProfile } from "../patientProfile/patientProfile.component.js";
import { BiometricStatusTag } from "../appointmentStatusTag/appointmentStatusTag.component.js";
import Panel from "../../enums/panel.enum.js";
import { MessageOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import { getRequisitionFileLink } from "../../services/order.service"; // Import the function to fetch requisition file links

import "./style.scss";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Text } = Typography;
const DAYS = 120;

export const Appointments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dates, setDates] = useState([]);
  const [timeZoneId, setTimeZoneId] = useState();
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [isBiometricsVisible, setIsBiometricsVisible] = useState(false);
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const [appointment, setAppointment] = useState();
  const [patient, setPatient] = useState();
  const swipeActionRef = useRef([]);
  const [phlebotomist, setPhlebotomist] = useState();

  useEffect(() => {
    fetchPhlebotomist();
  }, []);

  useEffect(() => {
    if (phlebotomist) {
      const timezone = phlebotomist.location?.timeZoneId ? phlebotomist.location?.timeZoneId : 'America/Los_Angeles';
      dayjs.tz.setDefault(timezone);
      setTimeZoneId(timezone);
    }
  }, [phlebotomist]);

  useEffect(() => {
    if (phlebotomist && timeZoneId) 
      fetchDates();
  }, [phlebotomist, timeZoneId]);

  const fetchPhlebotomist = async () => {
    setPhlebotomist(await getMe());
  };

  const fetchDates = async () => {
    let appointments = await listAppointments({
      populate: [{
        path: 'patients'
      }, {
        path: 'orders',
        populate: {
          path: 'lab'
        }
      }]
    })
    appointments = appointments.filter(({ patients }) => patients?.length)

    const updatedAppointments = await Promise.all(
      appointments.map(async (appointment) => {
        const updatedOrders = appointment.orders?.length ? await Promise.all(
          appointment.orders.map(async (order) => {
            if (order.reqFileName) {
              order.reqFileLink = await getRequisitionFileLink(order.reqFileName);
            }
            return order;
          })
        ) : []
        appointment.orders = updatedOrders;
        return appointment;
      })
    );

    setDates(
      Array.from(Array(DAYS))
        .map((_, i) => {
          const date = dayjs().subtract(3, "day").add(i, "days");
          return {
            date,
            appointments: updatedAppointments
              .filter(({ start }) => {
                const startDay = dayjs(date).tz(timeZoneId).startOf("day");
                const endDay = dayjs(date).tz(timeZoneId).endOf("day");
                return dayjs(start) > startDay && dayjs(start) < endDay;
              })
              .sort((a, b) => {
                const aStart = dayjs(a.start).tz(timeZoneId).toDate();
                const bStart = dayjs(b.start).tz(timeZoneId).toDate();
                return aStart - bStart;
              }),
          };
        })
        .filter((appointmentsByDate) => {
          return appointmentsByDate.appointments.length;
        })
    );
    setIsLoading(false);
  };

  const openMap = (appointment) => {
    if (!appointment.location)
      appointment.location = appointment.patients[0].location;

    const { streetAddress, city, postalCode } = appointment.location;
    const location = `${streetAddress}, ${city} ${postalCode}`;
    const url = `http://maps.google.com/?daddr=${location.replace(/\s/g, "+")}`;
    window.location.href = url;
    message.info("Google maps opened");
  };

  const openBiometrics = (appointment, patient) => {
    setIsBiometricsVisible(true);
    setAppointment(appointment);
    setPatient(patient);
  };

  const openProfile = (appointment) => {
    setIsProfileVisible(true);
    setAppointment(appointment);
  };

  const contactPatient = (appointment) => {
    const phones = appointment.patients
      .map(({ phone }) => phone.replace(/\D/g, ""))
      .join(",");
    const isAndroid = navigator.userAgent.toLowerCase().includes("android");
    window.open(isAndroid ? `sms:${phones}` : `sms:/open?addresses=${phones}`);
  };

  const openLabel = async (appointment) => {
    const orders = appointment.orders.map((order) => order._id).join(",");
    window.open(`/labels?orderIds=${orders}`, "_blank");
  };

  async function mergePDFs(pdfUrls) {
    const pdfDoc = await PDFDocument.create();
  
    for (let url of pdfUrls) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch PDF from ${url}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        const pdf = await PDFDocument.load(arrayBuffer);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach(page => pdfDoc.addPage(page));
      } catch (error) {
        console.error(`Error fetching or processing PDF from ${url}:`, error);
        message.error(`Error fetching or processing PDF from ${url}`);
      }
    }
  
    const mergedPdfArrayBuffer = await pdfDoc.save();
    return new Blob([mergedPdfArrayBuffer], { type: "application/pdf" });
  }
  
  const openReqLink = async (appointment) => {
    const pdfUrls = appointment.orders.map(order => order.reqFileLink).filter(url => url);
    if (pdfUrls.length === 0) {
      message.error("No requisition files available");
      return;
    }
  
    if (pdfUrls.length === 1) {
      window.open(pdfUrls[0], '_blank');
    } else {
      try {
        const mergedPdfBlob = await mergePDFs(pdfUrls);
        if (mergedPdfBlob) {
          const pdfUrl = URL.createObjectURL(mergedPdfBlob);
          const newWindow = window.open(pdfUrl, '_blank');
          if (!newWindow) {
            console.error('Failed to open new window for merged PDF');
            alert('Popup blocked. Please allow popups for this site.');
          }
        }
      } catch (error) {
        console.error('Error merging PDFs:', error);
        message.error("Failed to merge PDFs");
      }
    }
  };
  

  const Action = {
    PROFILE: "Info",
    LABEL: "Labels",
    REQ: "Reqs",
    MAPS: "Map",
    CONTACT: <><MessageOutlined style={{fontSize: '20px'}} /></>,
  };

  const tubes = {
    [Panel.ADVANCED_BASELINE]: {
      'SST': 2,
      'LAVENDAR': 2,
      'POUR OFF(CITRATED)': 1
    },
    [Panel.ADVANCED_FOLLOWUP]: {
      'SST': 2,
      'LAVENDAR': 1,
      'POUR OFF(CITRATED)': 1
    },
    [Panel.GALLERI]: {
      'CUSTOM': 2
    },
    [Panel.ATHLETE]: {
      'SST': 4,
      'ROYAL BLUE': 2,
      'LAVENDAR': 1,
      'POUR OFF(CITRATED)': 1
    },
    [Panel.CHOLESTEROL_BALANCE]: {
      'SST': 1,
    }
  };



  return !isLoading && phlebotomist && timeZoneId && dates?.length > 0 ? (
    <div className="appointments-component">
      {dates?.map(({ date, appointments: appointmentsByDate }, index) => (
        <div key={date} className={`date-${index}`}>
          <div className="page-header">
            {date
              .tz(timeZoneId)
              .format("dddd MMM D, YYYY")}

            {date.isSame(currentTime, "day") && (
              <Tag color="primary" className="today-tag">
                Today
              </Tag>
            )}
          </div>

          {appointmentsByDate.map((appointment) => (
            <SwipeAction
              key={appointment._id}
              onAction={({ key }) => {
                switch (key) {
                  case Action.MAPS:
                    openMap(appointment);
                    break;
                  case Action.PROFILE:
                    openProfile(appointment);
                    break;
                  case Action.CONTACT:
                    contactPatient(appointment);
                    break;
                  case Action.LABEL:
                    openLabel(appointment);
                    break;
                  case Action.REQ:
                    openReqLink(appointment);
                    break;
                  default:
                    break;
                }
              }}
              rightActions={Object.values(Action).map((key) => {
                return {
                  key,
                  text: key,
                  color: "primary",
                };
              })}
              closeOnTouchOutside={false}
              closeOnAction={false}
              ref={(el) => (swipeActionRef.current[appointment._id] = el)}
            >
              <div className="swipe-action-content">
                <Row>
                  <Col className="appointment-timing" 
                    xs={5}
                    sm={3}
                  >
                    <Text className="appointment-start">
                      {dayjs(appointment.start)
                        .tz(timeZoneId)
                        .format("h:mma")}
                    </Text>
                  </Col>

                  <Col 
                    xs={19}
                    sm={21}
                  >
                    {appointment.patients.map((patient) => {
                      let orders = appointment.orders.filter((order) => order.patient === patient._id);
                      if (!orders) return null;
                      
                      return (
                        <div
                          key={patient._id}
                          className="appointment-patient"
                          onClick={() => openBiometrics(appointment, patient)}
                        >
                          {patient.firstName} {patient.lastName}
                          <BiometricStatusTag
                            appointment={appointment}
                            patient={patient}
                          /> 
                          <br/>
                          
                          {orders.map((order, index) => (
                            <span className="panel-item" key={index}>
                              <code>{order.lab ? order.lab?.abbreviation :'TBD lab' }:</code>    
                              
                              {tubes[order.panel] ? (
                                <>{
                                  Object.keys(tubes[order.panel]).map(tubeType => (
                                    <Tag 
                                      className="tube-item"
                                      color="#fafafa"
                                      key={`tube-${tubeType}-${order._id}`}
                                    >
                                      <b>{tubes[order.panel][tubeType]}</b> {" "} {tubeType}  
                                    </Tag>
                                  ))
                                }</>
                              ) : (
                                <span className="no-tube-info">TBD</span>
                              )}

                            </span>
                          ))}
                        </div>
                      );
                    })}

                    {appointment.location && (
                      <Text className="appointment-address">
                        {appointment.location.streetAddress}{" "}
                        {appointment.location.streetAddress2} •{" "}
                        {appointment.location.city} {appointment.location.state}
                      </Text>
                    )}

                    {!appointment.location && (
                      <Text className="appointment-address">
                        {appointment.patients[0].location.streetAddress}{" "}
                        {appointment.patients[0].location.streetAddress2} •{" "}
                        {appointment.patients[0].location.city}{" "}
                        {appointment.patients[0].location.state}
                      </Text>
                    )}
                  </Col>
                </Row>
              </div>
            </SwipeAction>
          ))}
        </div>
      ))}

      <BiometricForm
        appointment={appointment}
        patient={patient}
        setAppointment={setAppointment}
        visible={isBiometricsVisible}
        setVisible={setIsBiometricsVisible}
        dates={dates}
        setDates={setDates}
      />

      <PatientProfile
        visible={isProfileVisible}
        setVisible={setIsProfileVisible}
        appointment={appointment}
      />
    </div>
  ) : null;
};
