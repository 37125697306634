import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { Menu } from 'antd';
import './style.scss'

const { Item } = Menu

export const NavMenu = ({ items }) => {
	const navigate = useNavigate()
	const location = useLocation()

	return (
		<Menu mode="inline" selectedKeys={[location.pathname]}>
			{items.map(item => (
				<Item 
					key={item.path} 
					onClick={() => {
						if (item.onClick) {
							item.onClick()
						} else {
							navigate(item.path)
						}
					}}
				>
					{item.text}
				</Item>
			))}
		</Menu>
	)
}