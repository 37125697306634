import React, { useEffect, useState } from "react";
import { message, Row, Button } from "antd";
import {
  UserOutlined,
  HomeOutlined,
  PhoneOutlined,
  CalendarOutlined,
  TagOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import * as Barcode from "react-barcode";
import "./style.scss";
import { listLabels } from "../../services/label.service.js";

export const PrintLabels = () => {
  const { search } = useLocation();
  const orderIds = new URLSearchParams(search).get("orderIds").split(",");
  const [labels, setLabels] = useState([]);
  const LabelKey = {
    INSTALAB: "instalab",
    LAB: "lab",
  };
  const LabelCount = {
    [LabelKey.INSTALAB]: 0,
    [LabelKey.LAB]: 6,
  };

  const ROWS =
    (LabelCount[LabelKey.INSTALAB] + LabelCount[LabelKey.LAB]) *
    orderIds.length;

  useEffect(() => {
    fetchLabels();
  }, []);


  const fetchLabels = async () => {
    try {
      const data = await listLabels({ filters: { orderIds } });
      setLabels(
        data.reduce((acc, label) => {
          for (const key of Object.keys(label)) {
            acc = [
              ...acc,
              ...Array.from(Array(LabelCount[key])).map(() => {
                return {
                  ...label[key],
                  key,
                };
              }),
            ];
          }
          return acc;
        }, [])
      );
    } catch (err) {
      console.log('err', err)
      message.error("Failed to load orders");
    }
  };

  const getLabel = (rowIndex) => {
    if (labels.length <= rowIndex) return null;
    return labels[rowIndex];
  };

  function print() {
    window.print();
  }

  return labels?.length ? (
    <div className="print-labels-component">
      <div className="print-button" onClick={print}>
        <Button type="primary">
          <PrinterOutlined /> Print Labels
        </Button>
      </div>

      {Array.from(Array(ROWS)).map((_, rowIndex) => {
        const label = getLabel(rowIndex);

        return (
          <Row className="label" key={`row_${rowIndex}`}>
            <div className="label-cell">
              <div className={`${label?.key}-cell-content`}>
                {label?.key === LabelKey.INSTALAB && (
                  <>
                    <div className="instalab-data">
                      <table>
                        <tr>
                          <td>
                            <UserOutlined />
                          </td>
                          <td className="ellipsis-col">{label.patientName}</td>
                        </tr>
                        <tr>
                          <td>
                            <HomeOutlined />
                          </td>
                          <td>{label.patientLocation}</td>
                        </tr>
                        <tr>
                          <td>
                            <PhoneOutlined />
                          </td>
                          <td>{label.patientPhone}</td>
                        </tr>
                        <tr>
                          <td>
                            <CalendarOutlined />
                          </td>
                          <td>{label.appointmentStart}</td>
                        </tr>
                        <tr>
                          <td>
                            <TagOutlined />
                          </td>
                          <td>{label.orderLabId}</td>
                        </tr>
                      </table>
                    </div>
                  </>
                )}
                {label?.key === LabelKey.LAB && (
                  <>
                    {label.barcode && (
                      <div className="barcode-container">
                        <Barcode
                          value={label.barcode}
                          displayValue={label.orderLabId}
                          width={1}
                          height={13}
                          margin={0}
                          fontSize={5}
                        />
                      </div>
                    )}
                    <table className="lab-table">
                      <tr>
                        <td>Name</td>
                        <td>{label.patientName}</td>
                      </tr>
                      <tr>
                        <td>DOB</td>
                        <td>{label.patientDob}</td>
                      </tr>
                      <tr>
                        <td>Collected</td>
                        <td>
                            {/* {label.appointmentStart} */}
                        </td>
                      </tr>
                    </table>
                  </>
                )}
              </div>
            </div>
          </Row>
        );
      })}
    </div>
  ) : null;
};
