import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";
import { getMe } from '../../services/user.service.js';
import './style.scss'

export const Badge = () => {
    const [phlebotomist, setPhlebotomist] = useState()

    useEffect(() => {
        fetchMe()
    }, [])

    const fetchMe = async () => {
        setPhlebotomist(await getMe())
    }

    if (!phlebotomist) return null

    return <div className="badge-component">

        <div className="badge-photo-container">
            <img src={phlebotomist.photo} alt={phlebotomist.firstName} />
        </div>
        
        <div className="badge-name-container">
            <div className="badge-name">{phlebotomist.firstName} {phlebotomist.lastName}</div>
        </div>

        <div className="badge-title-container">
            <div className="badge-title">Lead Phlebotomist</div>
        </div>

        <div className="badge-qr-code-container">
            <QRCode
                value={"https://instalab.com/verified>"}
                style={{ height: "auto", maxWidth: "100%", width: "40%" }}
            />
        </div>
    </div>

}