const TestCode = Object.freeze({
  ALBUMIN: 0,
  ALP: 1,
  ALT: 2,
  ANION_GAP: 3,
  APO_B: 4,
  APO_E: 5,
  AST: 6,
  BASOPHILS: 7,
  BICARBONATE: 8,
  BMI: 9,
  BP_D: 10,
  BP_S: 11,
  BUN_CREATINE: 12,
  BUN: 13,
  CALCIUM: 14,
  CHLORIDE: 15,
  CREATININE: 16,
  CYSTATIN_C: 17,
  DIRECT_BILIRUBIN: 18,
  DIRECT_LDL: 19,
  EGFR: 20,
  EOSINOPHILS: 21,
  FACTOR_II: 22,
  FACTOR_V: 23,
  FERRITIN: 24,
  FREET4: 25,
  GLOBULIN: 26,
  GLUCOSE: 27,
  HBA1C: 28,
  HDL: 29,
  HEIGHT: 30,
  HEMATOCRIT: 31,
  HEMOGLOBIN: 32,
  HOMAIR: 33,
  HOMOCYSTEINE: 34,
  HS_CRP: 35,
  INSULIN: 36,
  LIPOPROTEIN_A: 37,
  LP_PLA2: 38,
  LYMPHOCYTES: 39,
  MAGNESIUM: 40,
  MCH: 41,
  MCHC: 42,
  MCV: 43,
  MONOCYTES: 44,
  MPV: 45,
  MTHFR: 46,
  NEUTROPHILS: 47,
  NON_HDL: 48,
  PLATELET: 49,
  POTASSIUM: 50,
  PTT_ACTIVATED: 51,
  PULSE: 52,
  RBC: 53,
  RDW: 54,
  SD_LDL: 55,
  SODIUM: 56,
  FREET3: 57,
  TOTALT3: 58,
  TOTALT4: 59,
  TC: 60,
  TG_HDL: 61,
  TG: 62,
  TOTAL_BILIRUBIN: 63,
  TOTAL_PROTEIN: 64,
  TSH: 65,
  URIC_ACID: 66,
  VITAMIN_D: 67,
  VLDL: 68,
  WAIST: 69,
  WBC: 70,
  WEIGHT: 71,
  NEUTROPHILS_PERCENTAGE: 72,
  LYMPHOCYTES_PERCENTAGE: 73,
  MONOCYTES_PERCENTAGE: 74,
  EOSINOPHILS_PERCENTAGE: 75,
  BASOPHILS_PERCENTAGE: 76,
  MICROALBUMIN: 77,
  APO_A: 78,
  IRON: 79,
  URINE_CREATININE: 80,
  PT: 81,
  INR: 82,
  MYELOPEROXIDASE: 83,
  CPEPTIDE: 84,
  GLYCOMARK: 85,
  TROPONIN: 86,
  ADIPONECTIN: 87,
  LEPTIN: 88,
  TOTAL_TESTOSTERONE: 89,
  FREE_TESTOSTERONE: 90,
  IGFI: 91,
  SHBG: 92,
  ANA_PATTERN: 93,
  ANA_SCREEN: 94,
  ANA_TITER: 95,
  PSA_TOTAL: 96,
  PROTHROMBIN_TIME: 97,
  PARTIAL_THROMBOPLASTIN_TIME_ACTIVATED: 99,
  URINALYSIS_URINE_COLOR: 100,
  URINALYSIS_URINE_APPEARANCE: 101,
  URINALYSIS_UROBILINOGEN: 102,
  URINALYSIS_PH: 103,
  URINALYSIS_LEUKOCYTES: 105,
  URINALYSIS_NITRITE: 106,
  URINALYSIS_PROTEIN: 107,
  URINALYSIS_OCCULT_BLOOD: 108,
  URINALYSIS_KETONE: 109,
  URINALYSIS_BILIRUBIN: 110,
  URINALYSIS_GLUCOSE: 111,
  VITAMIN_B12: 112,
  IRON_TOTAL: 113,
  GGT: 114,
  CREATINE_KINASE: 115,
  FOLATE: 116,
  BP: 117,
  ESTRADIOL: 118,
  GRIP: 119,
  LH: 121,
  FSH: 122,
  TESTOSTERONE_TOTAL: 123,
  GRIP_RIGHT: 124,
  GRIP_LEFT: 125,
  CAMPESTEROL: 126,
  SITOSTEROL: 127,
  LATHOSTEROL: 128,
  DESMOSTEROL: 129,
  REVERSE_T3: 130,
  THYROGLOBULIN_ANTIBODY: 131,
  TPO_ANTIBODY: 132,
  OMEGA_3: 133,
  URINALYSIS_SPECIFIC_GRAVITY: 134,
  URINALYSIS_MICROSCOPIC_WBC: 135,
  URINALYSIS_MICROSCOPIC_RBC: 136, 
  URINALYSIS_MICROSCOPIC_SQUAMOUS_EPITHELIAL: 137,
  URINALYSIS_MICROSCOPIC_BACTERIA: 138,
  URINALYSIS_MICROSCOPIC_HYALINE_CAST: 139,
  T3_UPTAKE: 140,
  FREET4_INDEX: 142,
  TIBC: 143,
  IRON_SATURATION: 144,
  ALBUMIN_GLOBULIN: 145,
  IGA: 146,
  IGA_ANTITTG: 147,
  HIV_AG_AB: 148,
  HIV_AB_DIFFERENTIATION: 149,
  BODY_FAT: 150,
  BODY_WATER: 151,
  BONE_MASS: 152,
  TRAILING_WEEK_SLEEP_HEART_RATE: 153,
  TRAILING_WEEK_SLEEP_HOURS: 154,
  TRAILING_WEEK_STEPS: 155,
  FREE_TESTOSTERONE_DIRECT: 156,
  LDL_CHOLESTEROL_NONFASTING: 157,
  TG_NONFASTING: 158,
  HEP_C_AB: 159,
  DHEA_S: 160,
  CORTISOL: 161,
  RBC_MAGNESIUM: 162,
  CRP: 163,
  MYELOPEROXIDASE_AB: 164,
  PROTEINASE3_AB: 165,
  TOTAL_IGE: 166,
  ALTERNARIA_ALTERNATA_MOLD_IGE: 167,
  ASPERGILUUS_FUMIGATUS_MOLD_IGE: 168,
  BERMUDA_GRASS_IGE: 169,
  BIRCH_TREE_IGE: 170,
  CAT_DANDER_IGE: 171,
  CLADOSPORIUM_HERBARUM_MOLD_IGE: 172,
  DOG_DANDER_IGE: 173,
  DUST_MITE_DF_IGE: 174,
  DUST_MITE_DP_IGE: 175,
  ELM_TREE_IGE: 176,
  JOHNSON_GRASS_IGE: 177,
  LAMBS_QUARTERS_WEED_IGE: 178,
  MOUNTAIN_CEDAR_TREE_IGE: 179,
  MUGWORT_WEED_IGE: 180,
  OAK_TREE_IGE: 181,
  OLIVE_FOOD_IGE: 182,
  RYEGRASS_IGE: 183,
  WALNUT_TREE_POLLEN_IGE: 184,
  WESTERN_RAGWEED_IGE: 185,
  IMMATURE_GRANULOCYTE_COUNT: 186,
  URINE_CULTURE_RESULT: 187,
  NEISSERIA_GONORRHOEAE_RNA: 188,
  CHLAMYDIA_RNA: 189,
  COVID19_RNA: 190,
  SKIN_BIOPSY_RIGHT_ABDOMEN: 191,
  SKIN_BIOPSY_MID_ABDOMEN: 192,
  SKIN_BIOPSY_LEFT_CHEST: 193,
  SKIN_BIOPSY_LEFT_UPPER_CHEST: 194,
  DEXA_TOTAL_BODY_FAT: 195,
  DEXA_VAT_MASS: 196,
  DEXA_TOTAL_LEAN_MASS: 197,
  DEXA_LEAN_MASS_ARMS: 198,
  DEXA_LEAN_MASS_LEGS: 199,
  ALMI: 200,
  FFMI: 201,
  TRANSFERRIN: 202,
  VITAMIN_B6: 203,
  SICKLE_CELL_SCREEN: 204,
  CORTISOL_PM: 205,
  PROGESTERONE: 206,
  TOTAL_HCG: 207,
  DDIMER: 208,
  PROLACTIN: 209,
  UIBC: 210,
  INDIRECT_BILIRUBIN: 211,
  CHOLESTANOL: 212,
  CHOLESTEROL_BALANCE_SCORE: 213,
  HEMOGLOBIN_A: 214,
  HEMOGLOBIN_A2: 215,
  HEMOGLOBIN_F: 216,
  PSA_FREE: 217,
  PSA_FREE_RATIO: 218
})

export default TestCode;