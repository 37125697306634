import React, { useEffect, useState, useRef } from "react";
import { listAppointmentBiometrics } from "../../services/appointment.service.js";
import "./style.scss";

export const BiometricStatusTag = ({ appointment, patient }) => {
  const [isCollected, setIsCollected] = useState();

  useEffect(() => {
    if (appointment) {
      fetchIsCollected();
    }

  }, [appointment]);

  const fetchIsCollected = async () => {
    const biometrics = await listAppointmentBiometrics(
      appointment._id,
      patient._id
    );

    setIsCollected(biometrics && Object.keys(biometrics).length > 7);
  };

  return (isCollected ?
    <span className="status-collected">✅</span> : null);
};

