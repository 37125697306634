import axios from 'axios'

export const listLabels = async (params={}) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/labels`, {
        params,
        headers: {
            'authorization': localStorage.getItem('token')
        }
    })
    return response.data
}

