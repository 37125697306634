import React, { useState, useEffect } from 'react'
import { Descriptions, Typography } from 'antd'
import { Modal, Button } from 'antd-mobile';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import './style.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

const { Text } = Typography
const { Item } = Descriptions

export const PatientProfile = ({ appointment, visible, setVisible }) => {
  const [location, setLocation] = useState()

  useEffect(() => {
    if (appointment) {
      setLocation(getLocation())
    }
  }, [appointment])


  const getLocation = () => {
    let location = appointment.location
    if (!location) location = appointment.patients[0].location
    const {
      streetAddress,
      streetAddress2,
      city,
      state,
      postalCode,

    } = location
    let address = streetAddress
    if (streetAddress2) address += ` ${streetAddress2}`
    return `${address}, ${city}, ${state} ${postalCode}`
  }

  const getEntryInstructions = () => {
    let location = appointment.location
    if (!location) location = appointment.patients[0].location
    return location.entryInstructions
  }

  const onClose = () => {
    setVisible(false)
  }


  return appointment ? (
    <div className="patient-profile-component">
      <Modal
        visible={visible}
        closeOnAction
        onClose={onClose}
        closeOnMaskClick={true}
        maskClassName="patient-profile-modal"
        content={<>
          <div className="patient-profile-header">
            {<Text>Appointment Info</Text>}
          </div>

          <div className="patient-profile-content">
            <Descriptions title="Appointment" bordered>
              <Item label="Time">
                {dayjs(appointment.start).tz(appointment.location.timeZoneId).format('MMM D, YYYY @ h:mma')}
              </Item>
            </Descriptions>

            <Descriptions title="Location" bordered>
              <Item label="Address">
                {location}
              </Item>
 

              {getEntryInstructions() && (
                <Item label="Entry">
                  {getEntryInstructions()}
                </Item>
              )}            

            </Descriptions>

            {appointment.patients.filter(patient => {
              return appointment.orders.some((order) => order.patient === patient._id);
            }).map((patient, index) => (
            <Descriptions title={`Patient #${index+1}`} bordered>
              <Item label="Name">
                {patient.firstName} {patient.lastName}
              </Item>

              <Item label="DOB">
                {patient.dob}
              </Item>

              <Item label="Phone">
                {patient.phone}
              </Item>
              
              {
                appointment?.orders
                  .filter(order => order.patient === patient._id)
                  .map((order, index) => (
                    <React.Fragment key={index}>
                      <Item label="Order">Panel: {order.panel}<br/>Lab: {order.lab?.name}</Item>
                    </React.Fragment>
                  ))
              }              
              {/* <Item label="Panel">{appointment?.orders.find(order => order.patient === patient._id)?.panel}</Item>
              <Item label="Lab">{appointment?.orders.find(order => order.patient === patient._id)?.lab?.name}</Item> */}

            </Descriptions>
            ))}
          </div>

          <div className="modal-footer">
            <Button
              className="cancel-btn"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </>}
      />
    </div>
  ) : null
}