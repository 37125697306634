import React from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom'
import './style.scss'

const { Sider } = Layout

const BLACKLIST = [
    "/labels"
]

export const Sidebar = ({ menu }) => {
    const location = useLocation()

    return BLACKLIST.some(match => location.pathname.includes(match)) ? null : (
        <Sider
            className="sidebar-component"
            breakpoint={"lg"}
            theme="light"
            defaultCollapsed
            collapsedWidth={0}
            trigger={null}
        >
            {menu}
        </Sider>
    )
}